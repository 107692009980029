import React from 'react'
import styled from 'styled-components'
import {
    Backersbgtop, Backersbg, sequoia, folius, binance,
    solanabackers,
    alamedabackers,
    defiallifadsf,
    m333,
    corner,
    thmanback,
    zee,
    sfermion,
    spark,
    morningstrr,
    lemniscoppp,
    wsss3x,
    solareco,
    openspace
} from '../Images'

const Backers = () => {
    const imgData = [
        { img: solanabackers, link: "https://solana.ventures/" },
        { img: alamedabackers, link: "https://www.alameda-research.com/" },
        { img: defiallifadsf, link: "https://www.defialliance.co/" },
        { img: m333, link: "http://m13.co/" },
        { img: corner, link: "https://cornerventures.com/" },
        { img: thmanback, link: "https://www.6thman.ventures/" },
        { img: zee, link: "https://zeeprime.capital/" },
        { img: sfermion, link: "https://www.sfermion.io/" },
        { img: spark, link: "https://sparkdigitalcapital.com/" },
        { img: morningstrr, link: "https://www.stepn.com/img/morningstrr.svg" },
        { img: lemniscoppp, link: "https://lemniscap.com/" },
        { img: wsss3x, link: "https://www.welindershi.com/" },
        { img: solareco, link: "https://www.solarecofund.com/" },
        { img: openspace, link: "https://www.openspace.vc/" },
    ]
    return (
        <MainWrapBakers>
            <BackgroundArea>
                <Mask />
                <BackgroundContent>
                    <BakersRow>
                        <a href="https://www.sequoiacap.com" target="_blank">
                            <img src={sequoia} width="314" alt="sequoia" />
                        </a>
                        <a href="https://www.folius.ventures/" target="_blank">
                            <img src={folius} width="370" alt="folius" />
                        </a>
                        <a href="https://www.binance.com/" target="_blank">
                            <img src={binance} width="288" alt="binance" />
                        </a>
                    </BakersRow>
                    <BakersGrid>
                        {imgData.map((item, key) => {
                            return (
                                <a href={item.link} key={key} target="_blank">
                                    <img src={item.img} alt={item.link} />
                                </a>
                            )
                        })}
                    </BakersGrid>
                </BackgroundContent>
            </BackgroundArea>
        </MainWrapBakers>
    )

}
const MainWrapBakers = styled.div`
    margin-bottom: -300px;  
`;
const BackgroundArea = styled.div`
    background: url(${Backersbgtop}) 0px 36.8px / 
    contain no-repeat, url(${Backersbg}) 0px 0px / cover no-repeat;
    padding-bottom: 300px;
    position: relative;
`;
const Mask = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 80px;
    background-color: rgb(255, 255, 255);
`;
const BackgroundContent = styled.div`
    padding-top: 256px;
    max-width: 1115px;
    width: 100%;
    margin: 0px auto;
    @media (max-width:768px) {
        padding-top: 200px;
    }
`;
const BakersRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 50px;
    border-bottom: 2.8px solid rgb(0, 0, 0);
    font-size: 0px;
    img {
        margin-bottom: 30px;
    }
    @media (max-width: 992px) {
        justify-content: center;
        img {
            width: 200px;
            padding: 0 30px;
        }
    }
`;
const BakersGrid = styled.div`
    padding: 82px 94px 0px;
    max-width: 916px;
    box-sizing: border-box;
    margin: 0px auto;
    a {
        display:inline-block;
        img{
            vertical-align: middle;
            width:100%;
        }
        &:nth-child(1){
            width: 246px;
            margin-right: 173px;
        }
        &:nth-child(2){
            width: 309px;
        }
        &:nth-child(3) {
            margin-top: 102px;
            margin-right: 175px;
            width: 132px;
        }
        &:nth-child(4) {
            width: 115px;
            margin-right: 204px;
        }
        &:nth-child(5) {
            width: 101px;
        }
        &:nth-child(6) {
            width: 158px;
            margin-right: 176px;
            margin-top: 111px;
        }
        &:nth-child(7) {
            width: 107px;
            margin-right: 106px;
        }
        &:nth-child(8) {
            width: 181px;
        }
        &:nth-child(9) {
            width: 193px;
            margin-top: 101px;
            margin-right: 65px;
        }
        &:nth-child(10) {
            width: 230px;
            margin-right: 49px;
        }
        &:nth-child(11) {
            width: 191px;
        }
        &:nth-child(12) {
            margin-top: 94px;
            width: 78px;
            margin-right: 181px;
        }
        &:nth-child(13) {
            width: 182px;
            margin-right: 104px;
        }
        &:nth-child(13) {
            width: 182px;
            margin-right: 104px;
        }
    }
    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(6, 54px);
        align-items: center;
        row-gap: 26px;
        padding: 20px 0px 0px !important;
        a {
            display: flex !important;
            justify-content: center;
            align-items: center;
            margin: 0px !important;
            height: 100%;
            width: 100% !important;
            &:nth-child(1){
                img {
                    width: 80px;
                }
            }
            &:nth-child(2){
                img {
                    width: 140px;
                }
            }
            &:nth-child(3) {
                img {
                    width: 53px;
                }
            }
            &:nth-child(4) {
                img {
                    width: 48px;
                }
            }
            &:nth-child(5) {
                img {
                    width: 40px;
                }
            }
            &:nth-child(6) {
                img {
                    width: 77px;
                }
            }
            &:nth-child(7) {
                img {
                    width: 40px;
                }
            }
            &:nth-child(8) {
                img {
                    width: 100px;
                }
            }
            &:nth-child(9) {
                img {
                    width: 108px;
                }
            }
            &:nth-child(10) {
                img {
                    width: 108px;
                }
            }
            &:nth-child(11) {
                img {
                    width: 100px;
                }
            }
            &:nth-child(12) {
                img {
                    width: 31px;
                }
            }
            &:nth-child(13) {
                img {
                    width: 100px;
                }
            }
            &:nth-child(14) {
                img {
                    width: 100px;
                }
            }
        }
    }
`;
export default Backers