import React from "react"
import GlobalStyle from "./components/GlobalStyle"
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'

const App = () => {
  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App;
