
import {
    jerry,
    yawn,
    jessica,
    ryan,
    scott,
    williamRobinson,
    jason,
    santiago
} from '../Images'

const TeamSlidesData = [
    { name: "JERRY HUANG", img: jerry, role: "CO-FOUNDER", linkedin: "https://www.linkedin.com/in/jerry2046", twitter: "https://twitter.com/Jerry10240", mask: "Decade in game development,operation and marketing, ranked most downloaded game in IOS app store." },
    { name: "YAWN RONG", img: yawn, role: "CO-FOUNDER", linkedin: "https://www.linkedin.com/in/yawn-rong-4b4b1861", twitter: "https://twitter.com/yawn_rong?s=11", mask: "Entrepreneur, crypto angel investor and start-up incubator. Experience in scaling small startup to national brand." },
    { name: "JESSICA DUAN", img: jessica, role: "CSO", linkedin: "https://twitter.com/yawn_rong?s=11", mask: "Jessica has a proven record of over 10 years in high end clients consulting services, building great teams and facilitating cross-industry collaborations." },
    { name: "RYAN TURNER", img: ryan, mask: "Utilises Illustration, Graphic, & Interior Design with a focus on creating unique brands and identities. Founded his design company, Malicious Delicious, in 2006 and cant imagine doing anything else." },
]
const AdvisorsData = [
    { name: "SCOTT DUNLAP", img: scott, role: "VP AT ADIDAS", linkedin: "https://www.linkedin.com/in/scottdunlap", twitter: "https://twitter.com/scott_dunlap" },
    { name: "WILLIAM ROBINSON", img: williamRobinson, role: "HEAD OF ACCELERATOR AT ALLIANCE", linkedin: "https://www.linkedin.com/in/wbarobinson/", twitter: "https://twitter.com/dangerwillrobin?s=21" },
    { name: "JASON KAM", img: jason, role: "FOUNDER OF FOLIUS VENTURES", linkedin: "https://www.linkedin.com/in/jason-kam-35080625/", twitter: "https://twitter.com/MapleLeafCap" },
    { name: "Santiago R. Santos", img: santiago, role: "Web3 Investor", linkedin: "https://www.linkedin.com/in/santiago-rs/", twitter: "https://twitter.com/santiagoroel" },
]

export {
    TeamSlidesData,
    AdvisorsData,
}