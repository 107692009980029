import React from "react"
import styled from "styled-components"
import { Linkedin, TwitterLink } from "../Images"

const TeamTemplate = ({ data }) => {
  return (
    <MainTemplateWrap>
      <div className="img">
        <img src={data.img} alt={data.name} />
        {data.mask &&
          <div className="mask">{data.mask}</div>
        }
      </div>
      <div className="profile">
        <div className="name">
          <div>{data.name}</div>
          <div className="link">
            {data.linkedin &&
              <a
                href={data.linkedin}
                target="_blank"
                referrerpolicy="no-referrer"
              >
                <img src={Linkedin} alt="" />
              </a>
            }
            {data.twitter &&
              <a
                href={data.twitter}
                target="_blank"
                referrerpolicy="no-referrer"
              >
                <img src={TwitterLink} alt="" />
              </a>
            }
          </div>
        </div>
        <div className="job">{data.role}</div>
      </div>
    </MainTemplateWrap>
  )
}

const MainTemplateWrap = styled.div`
  .img {
    position: relative;
    width: 100%;
    font-size: 0px;
    img {
      width: 100%;
      margin-bottom: 30px;
    }
    .mask {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: calc(100% - 30px);
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(1rem);
      padding: 20px;
      box-sizing: border-box;
      color: rgb(255, 255, 255);
      letter-spacing: -0.37px;
      font-family: "source-code";
      font-size: 16.6px;
      opacity: 0;
      transition: all 0.3s linear 0s;
    }
    &:hover {
      .mask {
        opacity: 1;
      }
    }
  }
  .name {
    font-family: "tt-black";
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      font-size: 13px;
      flex-direction: column;
      align-items: flex-start;
      line-height: 1.4;
      margin-top:10px;
    }
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 35px;
    }
  }
  .job {
    font-family: "source-code";
    font-weight: 900;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1 !important;
    }
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 35px;
    }
  }
  .link img {
    margin: 0px;
    @media (max-width: 767px) {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 20px;
      height: 20px;
    }
    @media (min-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }
  .link a + a {
    margin-left: 20px;
  }
  @media (max-width:768px) {
    .img {
      img {
        margin-bottom: 0;
      }
    }
  }
`;
export default TeamTemplate;
