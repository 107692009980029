import React from 'react'
import styled from 'styled-components'
import { MetaMask, walletConnect, binanceConnect, mathWallet, coinbase, safepal } from '../Images'

const ConnectModal = ({ setConnectModal }) => {
    return (
        <>
            <BackDrop onClick={() => setConnectModal(false)} />
            <ModalStyled>
                <div className="content">
                    <div className="wallets-wrapper">
                        <div className="wallet-box">
                            <MetaMask />
                            <p>MetaMask</p>
                            <span>Connect to your MetaMask Wallet</span>
                        </div>
                        <div className="wallet-box">
                            <img src={walletConnect} alt="walletConnect" />
                            <p>WalletConnect</p>
                            <span>Scan with WalletConnect to connect</span>
                        </div>
                        <div className="wallet-box">
                            <img src={binanceConnect} alt="binance-connect" />
                            <p>Binance</p>
                            <span>Binance Chain Wallet</span>
                        </div>
                        <div className="wallet-box">
                            <img src={mathWallet} alt="mathWallet" />
                            <p>Math</p>
                            <span>Math Wallet</span>
                        </div>
                        <div className="wallet-box">
                            <img src={coinbase} alt="trustWallet" />
                            <p>Coinbase</p>
                            <span>Coinbase Wallet</span>
                        </div>
                        <div className="wallet-box">
                            <img src={safepal} alt="safepal" />
                            <p>SafePal</p>
                            <span>SafePal App</span>
                        </div>
                    </div>
                </div>
            </ModalStyled>
        </>
    )
}

const BackDrop = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
`;

const ModalStyled = styled.div`
    position: fixed;
    inset: 55% auto auto 50%;
    border: none;
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 12px;
    outline: none;
    padding: 0px;
    transform: translate(-50%, -50%);
    width: 100%;
    margin: 0px;
    max-width: 40%;
    min-width: fit-content;
    max-height: 100%;
    z-index: 1000;
    .content {
        width: 100%;
        height: 100%;
        position: relative;
        .wallets-wrapper {
            background-color: #fff;
            border-radius: 12px;
            margin: 0;
            padding: 0;
            opacity: 1;
            text-align: center;
            visibility: visible;
            pointer-events: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 100%;
            min-width: fit-content;
            max-height: 100%;
            overflow: auto;
            border: none;
            .wallet-box {
                width: 100%;
                height: 100%;
                padding: 24px 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                border-radius: 0;
                border: 1px solid hsla(0,0%,76.5%,.14);
                &:hover {
                    background-color: hsla(0,0%,76.5%,.14);
                }
                img, svg {
                    width: 50px;
                    height: auto;
                }
                p {
                    width: 100%;
                    font-size: 24px;
                    font-weight: 700;
                    margin-top: .5em;
                    color: #0c0c0d;
                }
                span {
                    width: 100%;
                    font-size: 18px;
                    margin: .333em 0;
                    color: #a9a9bc;
                }
            }
        }
    }
`;

export default ConnectModal