import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('root'));
document.addEventListener("DOMContentLoaded", function(event) { 
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  const loadingScreen = document.getElementById("pre_loader_st");
  setTimeout(() => {
    loadingScreen.classList.add("hide");
    document.querySelector("body").classList.remove("overflow-hidden");
  }, 3000);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
