import styled from 'styled-components'

const Wrapper = styled.div`
    .feature-icon {
        width: 100%;
        max-width: 1402px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0px auto;
        a {
            margin-bottom: 82px;
            margin-left: 75px;
            &:first-child {
                margin-left: 0;
            }
            img {
                width: 294px;
            }
        }
        @media (max-width: 1000px) {
            justify-content: space-around;
            padding: 0 20px;
            a {
                margin: 0 0 30px 0;
                width: 45%;
                img, svg {
                    max-width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }
    }
`;

const HeadingMainFirst = styled.div`
    text-align: center;
    max-width: 669px;
    width: 100%;
    margin: auto;
    @media (max-width:768px) {
        width: calc(100% - 70px);
        margin-top: 50px;
        margin-bottom: 70px;
    }
`;

const ShoeData = styled.div`
    &.pc {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 180px 0px;
        .data {
            margin-left: 10px;
            .item {
                display: flex;
                align-items: center;
                & + .item {
                    margin-top: 50px;
                }
                span:first-child {
                    font-family: "FuturaPT-Demi";
                    display: inline-block;
                    width: 270px;
                    height: 60px;
                    line-height: 60px;
                    text-align: left;
                    text-indent: 15px;
                    padding-right: 10px;
                    margin-right: 10px;
                    font-size: 32px;
                    letter-spacing: -0.4px;
                    background-color: rgb(35, 31, 32);
                    color: rgb(255, 255, 255);
                }
                span:last-child {
                    display: inline-flex;
                    align-items: center;
                    font-family: 'source-code-roman';
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 29px;
                    color: rgb(35, 31, 32);
                    white-space: nowrap;
                    svg {
                        margin-left: 20px;
                        width: 40px;
                        path {
                            fill: rgb(35, 31, 32);
                        }
                    }
                }
            }
        }
    }
    &.mbl {
        display: none;
    }
    @media (max-width:768px) {
        &.pc {
            display: none;
        }
        &.mbl {
            width: 100%;
            margin: 155px auto 0px;
            display: block;
            .shoe {
                height: 187px;
                position: relative;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                .line {
                    transform: none;
                }
                .line1 {
                    left: 30px;
                    top: -48px;
                }
                .line2 {
                    left: auto;
                    right: 30px;
                    top: -58px;
                }
                .line3 {
                    left: 74px;
                    top: 140px;
                }
                .item {
                    position: absolute;
                    z-index: 10;
                    top: -74px;
                    &:nth-child(1) {
                        left: 40px;
                        div:last-child {
                            text-align: left;
                            padding-left: 10px;
                        }
                        a {
                            left: 12px;
                        }
                    }
                    &:nth-child(2) {
                        right: 40px;
                        div:first-child {
                            text-align: right;
                        }
                        a {
                            right: 12px;
                        }
                    }
                    div:first-child {
                        font-family: 'source-code-roman';
                        font-weight: 600;
                        font-size: 14px;
                        color: rgb(35, 31, 32);
                        line-height: 18px;
                        margin-bottom: 6px;
                    }
                    div:last-child {
                        font-family: "FuturaPT-Demi";
                        width: 100px;
                        height: 32px;
                        text-indent: 0px;
                        line-height: 32px;
                        text-align: left;
                        padding-left: 10px;
                        font-size: 20px;
                        color: rgb(255, 255, 255);
                        background-color: rgb(35, 31, 32);
                    }
                    div {
                        a {
                            position: absolute;
                            top: -12px;
                        }
                        img, svg {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 22px;
                        }
                    }
                }
            }
            .other {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 70px;
                margin-top: 24px;
                .item + .item {
                    margin-top: 20px;
                }
                .item {
                    div:first-child {
                        font-family: 'source-code-roman';
                        font-weight: 600;
                        font-size: 14px;
                        color: rgb(35, 31, 32);
                        line-height: 18px;
                        margin-bottom: 6px;
                        white-space: nowrap;
                    }
                    div:last-child {
                        font-family: "FuturaPT-Demi";
                        width: 180px;
                        height: 32px;
                        line-height: 32px;
                        text-align: left;
                        text-indent: 8px;
                        font-size: 20px;
                        color: rgb(255, 255, 255);
                        background-color: rgb(35, 31, 32);
                    }
                }
            }
        }
    }
`;

const RoadMapStyled = styled.div`
    position: relative;
    z-index: 0;
    width: 100%;
    overflow: hidden;
    padding: 5vw 0px;
    .top {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        z-index: 1;
    }
    img {
        position: relative;
        width: 100%;
    }
    .bottom {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
    }
    .mbl{
        display:none;
    }
    @media (max-width:768px) {
        .pc {
            display: none;
        }
        .mbl{
            display:block;
        }
    }
`;

const EmailInput = styled.input`
    appearance: none;
    position: relative;
    z-index: 999;
    width: 100%;
    max-width: 962px;
    height: 76px;
    line-height: 76px;
    border: 4px solid rgb(0, 0, 0);
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    display: block;
    margin: 0px auto;
    font-family: "tt-black";
    &::placeholder {
        color: #BCBEC0;
    }
    @media (max-width:768px) {
        width: 300px;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        border-width: 2px;
    }
`;

const SubmitEmail = styled.input`
    display: block;
    cursor: pointer;
    width: 338px;
    height: 56px;
    margin: 28px auto 0px auto;
    background-color: rgb(114, 245, 150);
    text-align: center;
    line-height: 56px;
    font-size: 33px;
    font-family: "tt-black";
    border: none;
    margin-bottom: 180px;
    @media (max-width:768px) {
        margin-top: 10px;
        width: 130px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        border: none;
        outline: 0px;
        border-radius: 0px;
    }
`;

const TeamContainer = styled.div`
    width: 1250px;
    margin: 0px auto;
    user-select: none;
    @media (max-width:768px) {
        width: 100%;
        padding: 0px 20px;
    }
`;

export {
    Wrapper,
    HeadingMainFirst,
    ShoeData,
    RoadMapStyled,
    EmailInput,
    SubmitEmail,
    TeamContainer,
}