import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { DFlex } from '../Style'
import { Logo } from '../Images'

const Banner = ({ bg, setConnectModal }) => {
    const [MenuOpen, setMenuOpen] = useState(false)

    return (
        <>
            <BannerStyled style={{ backgroundImage: `url(${bg})` }}>
                <NavStyled>
                    <Link to="/">
                        <Logo className="logo" />
                    </Link>
                    <DFlex className="align-items-center">
                        <button
                            className="font-tt-black download-btn"
                            onClick={() => setConnectModal(true)}>
                            DOWNLOAD NOW</button>
                        <div
                            className={`menu ${MenuOpen ? 'active' : ''}`}
                            onClick={() => setMenuOpen(!MenuOpen)}>
                            <span className="menu-item"></span>
                            <span className="menu-item"></span>
                            <span className="menu-item"></span>
                        </div>
                    </DFlex>
                </NavStyled>
            </BannerStyled>
            <SideMenuStyled className={MenuOpen ? 'active' : ''}>
                <div>
                    <Link to="/">Home</Link>
                </div>
                <div>
                    <Link to="/">How to Play</Link>
                </div>
                <div>
                    <Link to="/">Whitepaper</Link>
                </div>
                <div>
                    <Link to="/">Litepaper</Link>
                </div>
                <div>
                    <Link to="/">LinkTree</Link>
                </div>
            </SideMenuStyled>
        </>
    )
}

const BannerStyled = styled.div`
    width: 100%;
    height: 1000px;
    background-repeat: no-repeat;
    background-position: center 0px;
    background-size: cover;
    overflow: hidden;
    margin-bottom: 140px;
    @media (max-width:768px) {
        height: 600px;
        margin-bottom: 100px;
    }
`;
const NavStyled = styled.nav`
    position: relative;
    z-index: 100;
    width: 100%;
    padding: 0 80px;
    margin: 70px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
        width: 106px;
        height: 106px;
    }
    .download-btn {
        height: 35px;
        line-height: 1;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(35, 31, 32);
        border-radius: 17.5px;
        font-size: 24px;
        letter-spacing: -0.2px;
        text-align: center;
        cursor: pointer;
        padding: 0px 10px;
        margin-right: 35px;
        animation: 2s linear 0s infinite normal none running breathe;
    }
    @keyframes breathe {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    .menu {
        cursor: pointer;
        z-index: 2;
        transition: all 0.2s linear 0s;
        width: 52px;
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
            display: inline-block;
            width: 100%;
            height: 12px;
            background-color: rgb(0, 0, 0);
            transition: all 0.1s linear 0s;
        }
        &.active {
            span:nth-child(1) {
                transform: translate(0px, 10px) scale(1, 0);
            }
            span:nth-child(3) {
                transform: translate(0px, -10px) scale(1, 0);
            }
        }
    }
    @media (max-width:768px) {
        margin-top: 20px;
        padding: 0 25px;
        .logo {
            width: 44px;
            height: 44px;
        }
        .download-btn {
            height: 30px;
            line-height: 30px;
            font-size: 12px;
        }
        .menu {
            width: 26px;
            height: 26px;
            span {
                height: 6px;
            }
        }
    }
`;

const SideMenuStyled = styled.div`
    position: fixed;
    right: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    transition: all 0.2s linear 0s;
    border: none;
    opacity: 0.9;
    transform: translate(100%, 0px) translateZ(0px);
    &.active {
        transform: translate(0px, 0px) translateZ(0px);
    }
    div {
        text-align: center;
        font-family: "tt-black";
        font-size: 40px;
        line-height: 48px;
        margin-top: 60px;
        &:nth-child(1) {
            margin-top: 180px;
        }
        a {
            color: black;
            text-decoration: none;
        }
    }
    @media (max-width:768px) {
        div {
            font-size: 20px;
            line-height: 24px;
            margin-top: 40px;
            &:nth-child(1) {
                margin-top: 120px;
            }
        }
    }
`;

export default Banner