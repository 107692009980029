import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}
:root {
}
body {
    font-family: "source-code";
    font-size: 15px;
    color: black;
    background-color: white;
}
.font-source-code {
    font-family: "source-code";
}
.font-tt-dbold {
    font-family: "tt-dbold";
}
.font-tt-xbold {
    font-family: "tt-xbold";
}
.font-tt-medium {
    font-family: "tt-medium";
}
.font-tt-black {
    font-family: "tt-black";
}
.w-200 {
    font-weight: 200;
}
.w-300 {
    font-weight: 300;
}
.w-400 {
    font-weight: 400;
}
.w-500 {
    font-weight: 500;
}
.w-600 {
    font-weight: 600;
}
.w-700 {
    font-weight: 700;
}
.align-items-center {
    align-items: center;
}
.arrow-slider{
    display:flex;
    justify-content: space-between;
    @media screen and (max-width: 767px){
        margin: 20px 0px;
    }
    @media screen and (min-width: 768px){
        margin: 10px 0px 40px;
    }
    svg{
        @media screen and (max-width: 767px){
            width: 19px;
            height: 15px;
        }
        @media screen and (min-width: 768px){
            width: 38px;
            height: 31px;
        }
        cursor: pointer;
    }
}
`;

export default GlobalStyle;
