import styled from 'styled-components'

const DFlex = styled.div`
    display: flex;
`;

const Heading = styled.h1`
    font-family: "tt-black";
    font-size: ${props => props.size || '40px'};
    line-height: 48px;
    letter-spacing: -1.05px;
    margin-bottom: ${props => props.mb || '52px'};
    margin-top: ${props => props.mt || '0px'};
    text-align: ${props => props.align};
    @media (max-width: 768px) {
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 30px;
    }
`;

const MaxWidth = styled.div`
    max-width: ${props => props.maxWidth};
    width: 100%;
    margin: ${props => props.margin};
`;

const Description = styled.p`
    font-weight: 400;
    font-family: "source-code-roman";
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.3px;
    margin-bottom: ${props => props.mb};
    @media (max-width: 768px) {
        line-height: 18px;
        font-size: 14px;
        letter-spacing: -0.2px;
    }
`;



export {
    DFlex,
    Heading,
    MaxWidth,
    Description
}