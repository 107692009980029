import React from "react"
import styled from "styled-components"
import {
  Discut,
  Downloadmedia,
  Email,
  FooterLayer,
  Medium,
  Reddit,
  Telegram,
  Twitter,
  footerMbl,
} from "../Images"

const Footer = () => {
  return (
    <FooterWrapper>
      <img src={FooterLayer} className="responsive" width="100%" />
      <img src={footerMbl} className="mbl" width="100%" />
      <SocailList className="list-unstyled">
        <li>
          <a
            href="https://discord.com/invite/stepn"
            target="_blank"
            rel="noreferrer"
          >
            <Discut width="40px" />
          </a>
        </li>
        <li>
          <a
            href="https://discord.com/invite/stepn"
            target="_blank"
            rel="noreferrer"
          >
            <Telegram width="36px" />
          </a>
        </li>
        <li>
          <a
            href="https://discord.com/invite/stepn"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter width="37px" />
          </a>
        </li>
        <li>
          <a
            href="https://discord.com/invite/stepn"
            target="_blank"
            rel="noreferrer"
          >
            <Reddit width="32px" />
          </a>
        </li>
        <li>
          <a
            href="https://discord.com/invite/stepn"
            target="_blank"
            rel="noreferrer"
          >
            <Medium width="204px" />
          </a>
        </li>
      </SocailList>
      <MediaContent className="media">
        <a href="mailto:hello@stepn.com">
          <Email />
          hello@stepn.com
        </a>
        <a href="/stepn_media_kit.zip" download="">
          <Downloadmedia />
          download media kit
        </a>
      </MediaContent>
      <Paragraph>
        <span>COPYRIGHT FINDSATOSHI LAB LTD. 2022 - ALL RIGHTS RESERVED</span>
        <span>&nbsp;丨&nbsp; POWERED BY FINDSATOSHI LAB LTD.</span>
      </Paragraph>
    </FooterWrapper>
  )
}
const FooterWrapper = styled.footer`
  position: relative;
  font-size: 0;
  .mbl {
    display: none;
  }
  @media (max-width: 768px) {
    .responsive {
      display: none;
    }
    .mbl {
      display: inherit;
    }
  }
`;
const SocailList = styled.ul`
  position: absolute;
  list-style: none;
  left: 0px;
  bottom: 10vw;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 768px){
    li + li {
      margin-left: 60px;
    }
  }
  @media (max-width: 767px){
    li + li {
      margin-left: 20px;
    }
    li:nth-child(5) svg {
      width: 26px !important;
    }
  }

  @media (max-width: 768px) {
    bottom: 170px;
    svg, img {
      height: 20px;
      width: 20px !important;
    }
  }
`;
const MediaContent = styled.div`
  position: absolute;
  left: 50%;
  width: 463px;
  transform: translate(-50%, 0px);
  bottom: 6vw;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: rgb(255, 255, 255);
    font-family: "source-code-roman";
    font-weight: 300;
    display: flex;
    text-decoration: none;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  @media (max-width: 768px) {
    bottom: 123px;
    font-size: 12px;
    width: calc(100vw - 40px);
    img, svg {
      width: 20px;
    }
  }
`;
const Paragraph = styled.p`
    font-family: "source-code-roman";
    font-weight: 600;
    position: absolute;
    width: 100%;
    bottom: 2vw;
    left: 50%;
    transform: translate(-50%, 0px);
    font-size: 18px;
    color: rgb(255, 255, 255);
    line-height: 25px;
    text-align: center;
    @media (max-width: 1024px) {
      bottom: 20px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      span {
        font-size: 10px;
        transform-origin: center center;
        transform: scale(calc(0.833333));
        display: block;
        white-space: nowrap;
        text-align: center;
        letter-spacing: 0px;
        margin: 0px auto;
        overflow: hidden;
      }
    }
`;
export default Footer;
