import React, { useState, useEffect } from "react"
import Banner from "../components/UI/Banner"
import Footer from "../components/UI/Footer"
import Backers from "../components/home/Backers"
import Slider from "../components/Slider"
import ConnectModal from "../components/home/ConnectModal"
import TeamTemplate from "../components/home/TeamTemplate"
import { Heading, Description } from '../components/Style'
import { TeamSlidesData, AdvisorsData } from "../components/home/SlidersData"
import {
    Wrapper,
    HeadingMainFirst,
    ShoeData,
    RoadMapStyled,
    EmailInput,
    SubmitEmail,
    TeamContainer,
} from "../components/home/Style"
import {
    homeBg,
    ShowLines,
    Discut,
    Twitter,
    roadmapPc,
    roadmapTop,
    roadmapBottom,
    bloomberg,
    yahooNews,
    yahooFinance,
    Cointelegraph,
    Benzinga,
    Nasdaq,
    Marketwatch,
    discordData,
    mblShoe,
    showwalllll,
    line1s,
    line2s,
    line3s,
    roadmapShouji,
    roadmapBottomMobile,
    ArrowLeft,
    ArrowRight
} from '../components/Images'
import { Navigation } from "swiper"
const Home = () => {
    const [connetModal, setConnectModal] = useState(false);
    const [distance, useDistance] = useState(1027914721.09);
    const [carbon, setCarbon] = useState(688297.088);
    const [calories, setCalories] = useState(638952038.284);
    const [discord, setDiscord] = useState(290434);
    const [follwers, setFollwers] = useState(323071);
    const IncrementData = () => {
        useDistance(distance + .01)
        setCarbon(carbon + .01)
        setCalories(carbon + .01)
    }
    const Increment = () => {
        IncrementData()
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            Increment()
        }, 500);
        return () => clearTimeout(timer);
    }, [distance])
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const navigationPrevRef1 = React.useRef(null)
    const navigationNextRef1 = React.useRef(null)
    return (
        <Wrapper>
            <Banner bg={homeBg} setConnectModal={setConnectModal} />
            <HeadingMainFirst>
                <Heading>
                    JOIN TODAY + MAKE
                    <br />
                    YOUR STEPS COUNT!
                </Heading>
                <Description mb="20px">
                    STEPN IS A WEB 3 LIFESTYLE APP
                    <br />
                    WITH SOCIAL-FI AND GAME-FI ELEMENTS
                </Description>
                <Description>
                    PLAYERS CAN MAKE HANDSOME EARNINGS BY
                    <br />
                    WALKING, JOGGING, OR RUNNING OUTDOORS
                </Description>
            </HeadingMainFirst>
            <ShoeData className="pc">
                <ShowLines />
                <div className="data">
                    <div className="item">
                        <span>{distance.toLocaleString()}</span>
                        <span>DISTANCE RUN (M)</span>
                    </div>
                    <div className="item">
                        <span>{carbon.toLocaleString()}</span>
                        <span>CARBON OFFSET (KG)</span>
                    </div>
                    <div className="item">
                        <span>{calories.toLocaleString()}</span>
                        <span>CALORIES BURNED (KCAL)</span>
                    </div>
                    <div className="item">
                        <span>{discord.toLocaleString()}</span>
                        <span>
                            DISCORD MEMBERS
                            <a href="https://discord.com/invite/stepn" target="_blank">
                                <Discut />
                            </a>
                        </span>
                    </div>
                    <div className="item">
                        <span>{follwers.toLocaleString()}</span>
                        <span>
                            TWITTER FOLLOWERS
                            <a href="https://twitter.com/Stepnofficial" target="_blank">
                                <Twitter />
                            </a>
                        </span>
                    </div>
                </div>
            </ShoeData>
            <ShoeData className="mbl">
                <div className="shoe">
                    <div className="item">
                        <div>
                            <a href="https://discord.com/invite/stepn" target="_blank">
                                <img src={discordData} alt="discord" />
                            </a>
                            <p>DISCORD</p>
                            <p>MEMBERS</p>
                        </div>
                        <div>298,567</div>
                    </div>
                    <div className="item">
                        <div>
                            <a href="https://discord.com/invite/stepn" target="_blank">
                                <img src={discordData} alt="discord" />
                            </a>
                            <p>DISCORD</p>
                            <p>MEMBERS</p>
                        </div>
                        <div>298,567</div>
                    </div>
                    <img src={showwalllll} alt="showwalllll" />
                    <img src={mblShoe} alt="mblShoe" />
                    <img src={line1s} alt="line" className="line line1" width="103" />
                    <img src={line2s} alt="line" className="line line2" width="117" />
                    <img src={line3s} alt="line" className="line line3" width="14" />
                </div>
                <div className="other">
                    <div className="item">
                        <p>DISTANCE RUN(M)</p>
                        <div>10,479,607,498.37</div>
                    </div>
                    <div className="item">
                        <div>
                            <p>CARBON OFFSET(KG)</p>
                        </div>
                        <div>701,719.7643</div>
                    </div>
                    <div className="item">
                        <div>
                            <p>CALORIES BURNED(KCAL)</p>
                        </div>
                        <div>651,412,412.214</div>
                    </div>
                </div>
            </ShoeData>
            <Heading align="center" size="50px">
                ROADMAP
            </Heading>
            <RoadMapStyled>
                <img src={roadmapTop} alt="road map top" className="top pc" />
                <img src={roadmapPc} alt="road map" className="pc" />
                <img src={roadmapBottom} alt="road map bottom" className="bottom pc" />
                <img src={roadmapShouji} alt="road map" className="mbl" />
                <img src={roadmapBottomMobile} alt="road map bottom" className="mbl bottom" />
            </RoadMapStyled>
            <form action="">
                <EmailInput type="email" placeholder="Email" />
                <SubmitEmail type="submit" />
            </form>
            <Heading align="center" size="50px">
                AS FEATURED IN
            </Heading>
            <div className="feature-icon">
                <a target="_blank" href="https://www.bloomberg.com/press-releases/2021-12-10/the-first-solana-nft-mobile-game-is-officially-released">
                    <img src={bloomberg} alt="bloomberg" />
                </a>
                <a target="_blank" href="https://www.bloomberg.com/press-releases/2021-12-10/the-first-solana-nft-mobile-game-is-officially-released">
                    <img src={yahooNews} alt="bloomberg" />
                </a>
                <a target="_blank" href="https://www.bloomberg.com/press-releases/2021-12-10/the-first-solana-nft-mobile-game-is-officially-released">
                    <img src={yahooFinance} alt="bloomberg" />
                </a>
                <a target="_blank" href="https://www.bloomberg.com/press-releases/2021-12-10/the-first-solana-nft-mobile-game-is-officially-released">
                    <Cointelegraph />
                </a>
                <a target="_blank" href="https://www.bloomberg.com/press-releases/2021-12-10/the-first-solana-nft-mobile-game-is-officially-released">
                    <Benzinga />
                </a>
                <a target="_blank" href="https://www.bloomberg.com/press-releases/2021-12-10/the-first-solana-nft-mobile-game-is-officially-released">
                    <Nasdaq />
                </a>
                <a target="_blank" href="https://www.bloomberg.com/press-releases/2021-12-10/the-first-solana-nft-mobile-game-is-officially-released">
                    <Marketwatch />
                </a>
            </div>
            <TeamContainer>
                <Heading align="center" size="50px" mt="100px">
                    THE TEAM
                </Heading>
                <div class="arrow-slider">
                    <div ref={navigationPrevRef}>
                        <ArrowLeft size={25} />
                    </div>
                    <div ref={navigationNextRef}>
                        <ArrowRight size={25} />
                    </div>
                </div>
                <Slider
                    spaceBetween={20}
                    slidesPerView={2}
                    ChildElements={{ data: TeamSlidesData, template: TeamTemplate }}
                    grabCursor={true}
                    onInit={(swiper) => {
                      swiper.params.navigation.prevEl = navigationPrevRef.current;
                      swiper.params.navigation.nextEl = navigationNextRef.current;
                      swiper.navigation.init();
                      swiper.navigation.update();
                    }}
                    modules={[Navigation]}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 80,
                        },
                    }}
                />
                <Heading align="center" size="50px" mt="180px">
                    THE ADVISORS
                </Heading>
                <div class="arrow-slider">
                    <div ref={navigationPrevRef1}>
                        <ArrowLeft size={25} />
                    </div>
                    <div ref={navigationNextRef1}>
                        <ArrowRight size={25} />
                    </div>
                </div>
                <Slider
                    spaceBetween={20}
                    slidesPerView={2}
                    ChildElements={{ data: AdvisorsData, template: TeamTemplate }}
                    grabCursor={true}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef1.current;
                        swiper.params.navigation.nextEl = navigationNextRef1.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    modules={[Navigation]}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 80,
                        },
                    }}
                />
            </TeamContainer>
            <Heading align="center" size="50px" mt="180px" mb="0">
                OUR BACKERS
            </Heading>
            <Backers />
            <Footer />
            {connetModal && <ConnectModal setConnectModal={setConnectModal} />}
        </Wrapper>
    )
}

export default Home
