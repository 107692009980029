import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/bundle"

const Slider = (props) => {

    const { ChildElements, ...newProps } = props

    return (

        <Swiper

            {...newProps}
        >

            {ChildElements.data.map((item, key) => {

                return (

                    <SwiperSlide key={key}>

                        <ChildElements.template data={item} />

                    </SwiperSlide>
                )

            })}

        </Swiper>
        
    )

}

export default Slider